import { SyntheticEvent, useEffect, useState } from 'react';
import {
  ControllerRenderProps,
  FieldError,
  useController,
  useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  COUNTRIES,
  IListingSubmissionData,
  IListingSubmissionDataKeys,
  INCOTERMS,
  ISRI_GRADE,
  METAL_TYPE,
} from '@frontend/api';
import { Translate } from '@frontend/translation';
import {
  Autocomplete,
  Button,
  FilePicker,
  humanFileSize,
  IFilePickerFile,
  Select,
  TextField,
} from '@frontend/ui-elements';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectChangeEvent, Typography } from '@mui/material';
import {
  CaptionBox,
  FieldsWrapper,
  FormBox,
  FormContainer,
} from './ListingForm.css';
import {
  MAX_FILE_SIZE,
  SUPPORTED_FORMATS,
  MIN_REQUIRED_FILES,
  FORM_ERRORS_TYPES,
} from './ListingForm.const';
import ListingFormProps from './ListingForm.types';
import getListingFormSchema from './ListingForm.utils';
import FormType from '../utils/FormType.types';
import {
  buildSelectDataByOptionsAndNamespace,
  ISelectDataOption,
} from '../utils/utils';
import DatePicker from '../../ui-csr/DatePicker/DatePicker';
import { useFormTracking } from '../utils/useFormTracking';
import {
  MAX_NUMBER_AFTER_DECIMAL,
  MIN_QUANTITY_REQUIRED_MT,
} from '../Forms.const';

export default function ListingForm({
  errors,
  isSuccess,
  isPending,
  onSubmit,
  submitTranslationId,
}: ListingFormProps) {
  const [invalidFiles, setInvalidFiles] = useState<IFilePickerFile[]>([]);
  const intl = useIntl();

  const required = intl.formatMessage({
    id: 'customer-platform.shared.components.forms.errors.required',
  });
  const formId = 'listing-form.form';
  const fileSize = intl.formatMessage(
    {
      id: 'customer-platform.shared.components.forms.errors.filepicker.file_size',
    },
    { MAX_FILE_SIZE: humanFileSize(MAX_FILE_SIZE) },
  );

  const minNumberOfFiles = intl.formatMessage(
    {
      id: 'customer-platform.shared.components.forms.errors.filepicker.number_of_files',
    },
    { NUMBER_OF_FILES: MIN_REQUIRED_FILES.toString() },
  );

  const maxNumbersAfterDecimal = intl.formatMessage(
    {
      id: 'customer-platform.shared.components.forms.errors.number.max_numbers_after_decimal',
    },
    { MAX_NUMBER_AFTER_DECIMAL: MAX_NUMBER_AFTER_DECIMAL.toString() },
  );

  const minQuantityRequired = intl.formatMessage(
    {
      id: 'customer-platform.shared.components.forms.errors.quantify.min-quantity-required',
    },
    { MIN_QUANTITY_REQUIRED_MT: MIN_QUANTITY_REQUIRED_MT.toString() },
  );

  const fileType = intl.formatMessage(
    {
      id: 'customer-platform.shared.components.forms.errors.filepicker.file_type',
    },
    { SUPPORTED_FORMATS: SUPPORTED_FORMATS.join(', ') },
  );

  const dateMustBeFuture = intl.formatMessage({
    id: 'customer-platform.shared.components.forms.errors.datepicker.date_past',
  });
  const dateMustBeValid = intl.formatMessage({
    id: 'customer-platform.shared.components.forms.errors.datepicker.date_invalid',
  });

  const metalTypeData = buildSelectDataByOptionsAndNamespace(METAL_TYPE, intl);
  const isriGradeData = buildSelectDataByOptionsAndNamespace(ISRI_GRADE, intl);
  const incotermsData = buildSelectDataByOptionsAndNamespace(INCOTERMS, intl);
  const materialFromCountryData = buildSelectDataByOptionsAndNamespace(
    COUNTRIES,
    intl,
    'country',
  );

  const listingFormSchema = getListingFormSchema({
    required,
    fileSize,
    fileType,
    minNumberOfFiles,
    maxNumbersAfterDecimal,
    minQuantityRequired,
    dateMustBeFuture,
    dateMustBeValid,
  });

  const {
    control,
    formState: { errors: formErrors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<FormType<IListingSubmissionData>>({
    defaultValues: {
      metalType: '',
      isriGrade: '',
      validity: '',
      quantity: '',
      incoterms: '',
      shippingFrom: '',
      materialFromCountry: '',
      preferredPaymentTerm: '',
      materialPhotos: [],
    },
    resolver: yupResolver<FormType<IListingSubmissionData>>(listingFormSchema),
  });

  const { field: metalTypeField } = useController({
    name: 'metalType',
    control,
  });
  const { field: isriGradeField } = useController({
    name: 'isriGrade',
    control,
  });
  const { field: incotermsField } = useController({
    name: 'incoterms',
    control,
  });
  const { field: validityField } = useController({
    name: 'validity',
    control,
  });
  const { field: materialPhotosField } = useController({
    name: 'materialPhotos',
    control,
  });

  const { field: materialFromCountryField } = useController({
    name: 'materialFromCountry',
    control,
  });
  const handleChange =
    (field: ControllerRenderProps) => (event: SelectChangeEvent<unknown>) => {
      field.onChange(event.target.value);
    };

  const handleAutocompleteChange =
    (field: ControllerRenderProps) =>
    (_: SyntheticEvent<Element, Event>, option: ISelectDataOption) => {
      field.onChange(option?.value);
    };

  const handleDatePickerChange = (value: Date) => {
    validityField.onChange(value ?? FORM_ERRORS_TYPES.INVALID_DATE);
  };

  const handleFilePickerChange = (
    files: IListingSubmissionData['materialPhotos'],
  ) => {
    const errorList = (formErrors?.materialPhotos || []) as FieldError[];
    const validFiles = files?.filter((file, index) => {
      return (
        Object.values(SUPPORTED_FORMATS).includes(file.type) &&
        file.size <= MAX_FILE_SIZE &&
        !(index in errorList)
      );
    });

    setInvalidFiles(
      files
        ?.map((file, index) => ({ file, originalIndex: index }))
        ?.filter(({ file }) => !validFiles?.includes(file))
        ?.map(({ file, originalIndex }) => {
          const errorMessage = [
            !Object.values(SUPPORTED_FORMATS).includes(file.type) && fileType,
            file.size > MAX_FILE_SIZE && fileSize,
            originalIndex in errorList && errorList[originalIndex]?.message,
          ]
            .filter(Boolean)
            .join(' ');

          return {
            name: file.name,
            size: file.size,
            type: file.type,
            errorMessage: errorMessage,
          } as IFilePickerFile;
        }) || [],
    );

    materialPhotosField.onChange(validFiles);
  };

  useEffect(() => {
    if (errors !== undefined) {
      Object.keys(errors).map(field =>
        setError(field as IListingSubmissionDataKeys, {
          type: 'manual',
          message: errors[field as IListingSubmissionDataKeys][0],
        }),
      );
    }
  }, [errors, setError]);

  useFormTracking({ formId, formErrors, isSuccess });

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [reset, isSuccess]);
  return (
    <FormContainer>
      <CaptionBox>
        <Typography variant="p1">
          <Translate id="customer-platform.shared.components.forms.listing-form.paragraph" />
        </Typography>
        <Typography component="p" variant="caption2">
          <Translate id="customer-platform.shared.components.forms.listing-form.caption" />
        </Typography>
      </CaptionBox>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        id={formId}
        name={formId}
      >
        <FormBox>
          <FieldsWrapper>
            <Select
              id="metal-type"
              label={
                <Translate id="customer-platform.shared.components.forms.listing-form.select.metal.type" />
              }
              options={metalTypeData.options}
              value={metalTypeField.value}
              onChange={handleChange(metalTypeField as ControllerRenderProps)}
              error={!!formErrors?.metalType}
              helperText={formErrors?.metalType?.message}
              required
              formId={formId}
            />
            <Autocomplete
              id="isri-grade"
              label={
                <Translate id="customer-platform.shared.components.forms.listing-form.select.isri_grade" />
              }
              options={isriGradeData.options}
              required={true}
              onChange={handleAutocompleteChange(
                isriGradeField as ControllerRenderProps,
              )}
              value={isriGradeData.options.find(
                option => option.value === isriGradeField?.value,
              )}
              error={!!formErrors?.isriGrade}
              helperText={formErrors?.isriGrade?.message}
              autoHighlight
              formId={formId}
            />
            <DatePicker
              id="validity"
              format="dd/MM/yyyy"
              label={
                <Translate id="customer-platform.shared.components.forms.listing-form.datepicker.label" />
              }
              value={validityField.value}
              onChange={handleDatePickerChange}
              error={!!formErrors?.validity}
              helperText={formErrors?.validity?.message}
              disablePast
              required
              formId={formId}
            />
            <TextField
              id="quantity"
              type="number"
              label={
                <Translate id="customer-platform.shared.components.forms.listing-form.textfield.quantity" />
              }
              {...register('quantity')}
              error={!!formErrors?.quantity}
              helperText={formErrors?.quantity?.message}
              required
              formId={formId}
            />
            <Select
              id="incoterms"
              label={
                <Translate id="customer-platform.shared.components.forms.listing-form.select.incoterms" />
              }
              options={incotermsData.options}
              value={incotermsField.value}
              onChange={handleChange(incotermsField as ControllerRenderProps)}
              error={!!formErrors?.incoterms}
              helperText={formErrors?.incoterms?.message}
              required
              formId={formId}
            />
            <TextField
              id="shipping-from"
              label={
                <Translate id="customer-platform.shared.components.forms.listing-form.textfield.shipping_from" />
              }
              {...register('shippingFrom')}
              error={!!formErrors?.shippingFrom}
              helperText={formErrors?.shippingFrom?.message}
              formId={formId}
            />
            <Autocomplete
              id="material-from-country"
              label={
                <Translate id="customer-platform.shared.components.forms.listing-form.textfield.material_from_country" />
              }
              options={materialFromCountryData.options}
              onChange={handleAutocompleteChange(
                materialFromCountryField as ControllerRenderProps,
              )}
              value={materialFromCountryData.options.find(
                option => option.value === materialFromCountryField?.value,
              )}
              error={!!formErrors?.materialFromCountry}
              helperText={formErrors?.materialFromCountry?.message}
              autoHighlight
              required
              formId={formId}
            />
            <TextField
              id="preferred-payment-term"
              label={
                <Translate id="customer-platform.shared.components.forms.listing-form.textfield.preferred_payment_term" />
              }
              {...register('preferredPaymentTerm')}
              error={!!formErrors?.preferredPaymentTerm}
              helperText={formErrors?.preferredPaymentTerm?.message}
              formId={formId}
            />
            <FilePicker
              id="material-photos-field"
              label={
                <Translate id="customer-platform.shared.components.forms.listing-form.filepicker.label" />
              }
              fileUploadButtonText={
                <Translate id="customer-platform.shared.components.forms.listing-form.filepicker.button" />
              }
              invalidValues={invalidFiles}
              value={
                materialPhotosField.value as IListingSubmissionData['materialPhotos']
              }
              onChange={handleFilePickerChange}
              errorMessageList={
                formErrors?.materialPhotos?.constructor === Array
                  ? formErrors?.materialPhotos
                  : []
              }
              helperText={formErrors?.materialPhotos?.message}
              required
            />
          </FieldsWrapper>
          <Button
            buttonType="primary"
            id="listing-form.button.submit"
            size="large"
            type="submit"
            isSubmitting={isPending}
          >
            <Translate id={submitTranslationId} />
          </Button>
        </FormBox>
      </form>
    </FormContainer>
  );
}
