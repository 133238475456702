import {
  ISellerOrderFileUploadErrorObject,
  ISellerOrderFileUploadSubmissionData,
  ISellerOrderFileUploadSubmissionDataKeys,
} from '@frontend/api';
import { Translate } from '@frontend/translation';
import {
  Button,
  FilePicker,
  humanFileSize,
  IFilePickerFile,
} from '@frontend/ui-elements';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  FieldError,
  FieldErrors,
  useController,
  useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  FileManagerButtonsWrapper,
  FileManagerFormWrapper,
  FileManagerWrapper,
} from './FileManagerForm.css';
import {
  FILE_MANAGER_MAX_FILE_SIZE,
  FILE_MANAGER_SUPPORTED_FORMATS,
} from './FileManagerForm.const';
import { FileManagerFormRef, FileManagerProps } from './FileManagerForm.types';
import getFileManagerFormSchema from './FileManagerForm.utils';
import FormType from '../utils/FormType.types';
import { useFormTracking } from '../utils/useFormTracking';

const FileManagerForm = forwardRef<FileManagerFormRef, FileManagerProps>(
  (
    {
      alreadyUploadedFiles,
      deleteList,
      handleDeleteList,
      errors,
      isSuccess,
      isPending,
      hasUnsavedChanges,
      fileVariant,
      onDownloadSingle,
      onDownloadAll,
      onSubmit,
    }: FileManagerProps,
    ref,
  ) => {
    const [invalidFiles, setInvalidFiles] = useState<IFilePickerFile[]>([]);
    const [showSubmitButton, setShowSubmitButton] = useState<boolean>(false);
    const intl = useIntl();
    const formId = `file-manager.${fileVariant}-form`;

    const fileSize = intl.formatMessage(
      {
        id: 'customer-platform.shared.components.forms.errors.filepicker.file_size',
      },
      { MAX_FILE_SIZE: humanFileSize(FILE_MANAGER_MAX_FILE_SIZE) },
    );
    const fileType = intl.formatMessage(
      {
        id: 'customer-platform.shared.components.forms.errors.filepicker.file_type',
      },
      {
        SUPPORTED_FORMATS: Object.keys(FILE_MANAGER_SUPPORTED_FORMATS)
          .join(', ')
          .toUpperCase(),
      },
    );

    const fileManagerFormSchema = getFileManagerFormSchema({
      fileSize,
      fileType,
    });

    const {
      control,
      formState: { errors: formErrors },
      handleSubmit,
      setError,
    } = useForm<FormType<ISellerOrderFileUploadSubmissionData>>({
      resolver: yupResolver<FormType<ISellerOrderFileUploadSubmissionData>>(
        fileManagerFormSchema,
      ),
    });

    const isSellerOrderDocument = (
      formErrors: FieldErrors<FormType<ISellerOrderFileUploadSubmissionData>>,
    ): formErrors is {
      sellerOrderDocuments?: FieldError;
    } => {
      return 'sellerOrderDocuments' in formErrors;
    };

    const isSellerOrderPhotos = (
      formErrors: FieldErrors<FormType<ISellerOrderFileUploadSubmissionData>>,
    ): formErrors is {
      sellerOrderPhotos?: FieldError;
    } => {
      return 'sellerOrderPhotos' in formErrors;
    };

    const { field: filePickerField } = useController({
      name:
        fileVariant === 'documents'
          ? 'sellerOrderDocuments'
          : 'sellerOrderPhotos',
      control,
    });

    const handleFilePickerChange = (files: File[] | undefined) => {
      const errorList = (
        isSellerOrderDocument(formErrors)
          ? formErrors?.sellerOrderDocuments
          : isSellerOrderPhotos(formErrors)
            ? formErrors?.sellerOrderPhotos
            : []
      ) as Record<number, FieldError>;

      const validFiles = files?.filter((file, index) => {
        return (
          Object.values(FILE_MANAGER_SUPPORTED_FORMATS).includes(file.type) &&
          file.size <= FILE_MANAGER_MAX_FILE_SIZE &&
          !(index in errorList)
        );
      });

      // setInvalidFiles is used to remove files from the input values if they fail their FE or BE validation because
      // in the current version we don't have a remove button if something does not validate there the need to remove it
      setInvalidFiles(
        files
          ?.map((file, index) => ({ file, originalIndex: index }))
          ?.filter(({ file }) => !validFiles?.includes(file))
          ?.map(({ file, originalIndex }) => {
            const errorMessage = [
              !Object.values(FILE_MANAGER_SUPPORTED_FORMATS).includes(
                file.type,
              ) && fileType,
              file.size > FILE_MANAGER_MAX_FILE_SIZE && fileSize,
              originalIndex in errorList && errorList[originalIndex]?.message,
            ]
              .filter(Boolean)
              .join(' ');

            return {
              name: file.name,
              size: file.size,
              type: file.type,
              errorMessage: errorMessage,
            } as IFilePickerFile;
          }) || [],
      );

      const hasFiles = (validFiles?.length as number) > 0;
      const hasFilesToDelete = deleteList.length > 0;

      hasUnsavedChanges(hasFiles);
      setShowSubmitButton(hasFiles || hasFilesToDelete);

      filePickerField.onChange(validFiles);
    };

    useEffect(() => {
      const formFieldIsEmpty =
        filePickerField.value === undefined ||
        filePickerField.value.length === 0;
      if (deleteList.length > 0 || !formFieldIsEmpty) {
        setShowSubmitButton(true);
      }
      if (deleteList.length === 0 && formFieldIsEmpty) {
        setShowSubmitButton(false);
      }
    }, [deleteList.length, filePickerField.value]);

    useEffect(() => {
      if (errors !== undefined) {
        Object.keys(errors).map(field => {
          return Object.entries(
            errors[field as ISellerOrderFileUploadSubmissionDataKeys] as {
              [key: number]: ISellerOrderFileUploadErrorObject;
            },
          ).forEach(
            ([fileIndex, fileErrors]: [
              string,
              ISellerOrderFileUploadErrorObject,
            ]) => {
              const fileErrorMessage = [
                fileErrors.fileType && fileErrors.fileType,
                fileErrors.fileSize && fileErrors.fileSize,
              ]
                .filter(Boolean)
                .join(' ');

              setError(
                `${field as ISellerOrderFileUploadSubmissionDataKeys}[${fileIndex}]`,
                {
                  type: 'be-response',
                  message: fileErrorMessage,
                } as FieldError,
              );
            },
          );
        });
        handleFilePickerChange(filePickerField.value as File[]);
      }
      // filePickerField and handleFilePickerChange cannot be added into the deps array
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors, setError]);

    useFormTracking({ formId, formErrors, isSuccess });

    useImperativeHandle(ref, () => ({
      invokeSubmit() {
        handleSubmit(onSubmit)();
      },
    }));

    return (
      <FileManagerWrapper>
        <Typography variant="p1">
          <Translate
            id={`customer-platform.listing-details.order-${fileVariant}.file-manager.caption`}
          />
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          id={formId}
          name={formId}
        >
          <FileManagerFormWrapper>
            <FilePicker
              id={`${fileVariant}.file-picker-field`}
              label={
                <Translate
                  id={`customer-platform.listing-details.order-${fileVariant}.file-manager.label`}
                />
              }
              fileUploadButtonText={
                <Translate
                  id={`customer-platform.listing-details.order-${fileVariant}.file-manager.button.upload`}
                />
              }
              deleteList={deleteList}
              onClickDeleteFromRemote={handleDeleteList}
              onClickDownload={onDownloadSingle}
              invalidValues={invalidFiles}
              remoteValues={alreadyUploadedFiles}
              acceptedFormats={Object.keys(FILE_MANAGER_SUPPORTED_FORMATS)}
              value={filePickerField.value as File[]}
              onChange={handleFilePickerChange}
              helperText={
                isSellerOrderDocument(formErrors)
                  ? formErrors?.sellerOrderDocuments?.message
                  : isSellerOrderPhotos(formErrors)
                    ? formErrors?.sellerOrderPhotos?.message
                    : undefined
              }
              errorMessageList={
                isSellerOrderDocument(formErrors)
                  ? formErrors?.sellerOrderDocuments
                  : isSellerOrderPhotos(formErrors)
                    ? formErrors?.sellerOrderPhotos
                    : []
              }
            />
            {(showSubmitButton ||
              (alreadyUploadedFiles && alreadyUploadedFiles.length > 0)) && (
              <FileManagerButtonsWrapper>
                {alreadyUploadedFiles && alreadyUploadedFiles.length > 0 && (
                  <Button
                    buttonType="secondary"
                    id={`${formId}.button.download-all`}
                    size="large"
                    onClick={onDownloadAll}
                    isSubmitting={isPending}
                  >
                    <Translate
                      id={`customer-platform.listing-details.order-${fileVariant}.file-manager.button.download-all`}
                    />
                  </Button>
                )}
                {showSubmitButton && (
                  <Button
                    buttonType="primary"
                    id={`${formId}.button.submit`}
                    size="large"
                    type="submit"
                    isSubmitting={isPending}
                  >
                    <Translate
                      id={`customer-platform.listing-details.order-${fileVariant}.file-manager.button.submit`}
                    />
                  </Button>
                )}
              </FileManagerButtonsWrapper>
            )}
          </FileManagerFormWrapper>
        </form>
      </FileManagerWrapper>
    );
  },
);

export default FileManagerForm;
